import moment from "moment";
export const makeTimes = (begin, end) => {
  const x = 15;
  const times = [];
  let tt = 0;
  let start = Number(begin.split(":")[0]);
  let endH = Number(end.split(":")[0]);
  for (var i = 0; tt <= (endH - start) * 60; i++) {
    let hh = Math.floor(tt / 60) + start;
    let mm = tt % 60;
    times[i] =
      hh < 10
        ? "0" + hh + ":" + ("0" + mm).slice(-2)
        : +hh + ":" + ("0" + mm).slice(-2);
    tt = tt + x;
  }
  return times;
};

export const makeTimeStamp = prev =>
  moment(9000000000000).format("YYYY-MM-DD HH:mm:ss");
