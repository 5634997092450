import React, { useEffect, useState } from "react";
import useBoxes from "../hooks/useBoxes";
import DatePicker from "react-datepicker";
import Popup from "reactjs-popup";
import { CircularProgress } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import * as uuid from "uuid";
import { excludeDates } from "../uitlity/momentPoly";
import de from "date-fns/locale/de";
import {
  Weekday,
  DailyComponent,
  CheckBoxes
} from "../ui_components/uiExporter";
import {
  Box,
  Button,
  Divider,
  CopyDelete,
  PopupParent,
  CheckInH2,
  PeriodParent,
  PeriodType,
  DateDiv,
  CheckinItem
} from "../styled_components/styled_box/";
import SaveNewPeriod from "../components/saveNewPeriod";
import { displayOptions, holidays } from "../uitlity/constants";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const Boxes = ({
  data,
  toDisable,
  i,
  setCurrentSelected,
  active,
  first,
  dropdown,
  setDropdown,
  parentElement
}) => {
  const {
    date,
    handleCopy,
    handleChange,
    setChecked,
    checked,
    handleDelete,
    sortdays,
    daily,
    handlePeriodType,
    store,
    handleSelected,
    noClick,
    anytime
  } = useBoxes({ data, setDropdown, dropdown });
  const [midPoint, setMidPoint] = useState();
  const [element, setElement] = useState();
  const [index, setIndex] = useState(0);
  const boxWidth = 262;
  const margin = 10;
  const goToDiv = (e, i, update = false) => {
    let a = parentElement;
    let b = boxWidth;
    let isMobile = false;
    if (window.innerWidth < 1024) {
      isMobile = true;
    }
    let deduct = isMobile ? 0 : b;
    if (update) {
      a.style.transform = `translate3d(${0}px,0,0)`;
      return;
    }
    if (store.filterPeriodsReducer.length <= 3 && !isMobile) {
      a.style.transform = `translate3d(${-deduct}px,0,0)`;
      return;
    }
    a.style.transform = `translate3d(${midPoint - i * element - deduct}px,0,0)`;
  };
  useEffect(() => {
    let w = 262;
    setElement(w + 2 * 10);
    setMidPoint(window.innerWidth / 2 - w / 2 - margin * 1.5);
    if (midPoint && element) {
      goToDiv("", first);
    }
    // eslint-disable-next-line
  }, [midPoint, element, first, store.filterPeriodsReducer]);

  useEffect(() => {
    goToDiv("", first, true);
    // eslint-disable-next-line
  }, [store.filterPeriodsReducer]);
  return (
    <>
      <Box
        key={uuid()}
        id={data.id}
        className={"child"}
        noClick={noClick}
        boxWidth={boxWidth}
        margin={margin}
        onClick={e => {
          if (e.target.type === undefined) {
            setCurrentSelected(data.id);
            setIndex(index + 1);
            goToDiv("", i);
          }
          setCurrentSelected(data.id);
        }}
      >
        <Popup
          key={uuid()}
          style={{ padding: 0 }}
          trigger={
            <Button>
              <FontAwesomeIcon icon={faEllipsisH} />
            </Button>
          }
          position="bottom left"
        >
          {() => (
            <PopupParent key={uuid()}>
              <CopyDelete onClick={e => handleCopy(e)}>
                Copy to new period
              </CopyDelete>
              <Divider />
              <CopyDelete
                onClick={e =>
                  window.confirm("Do you really want to delete") &&
                  handleDelete(e)
                }
              >
                Delete period
              </CopyDelete>
            </PopupParent>
          )}
        </Popup>
        <CheckinItem
          key={uuid()}
          past={
            moment(new Date(date)).format("YYYY-MM-DD") <
              moment(new Date()).format("YYYY-MM-DD") && !active
          }
          holiday={
            data.type === "holiday" ||
            holidays
              .map(x => Object.values(x)[0])
              .indexOf(moment(new Date(date)).format("YYYY-MM-DD")) !== -1
          }
        >
          <CheckInH2 key={uuid()}>Check-In Hours valid from</CheckInH2>
          <DateDiv>
            <DatePicker
              dateFormat="dd.MM.y"
              selected={
                new Date(date).getFullYear() > 2050 ? null : new Date(date)
              }
              onChange={date => handleChange(date)}
              onKeyDown={e => e.preventDefault()}
              locale={de}
              excludeDates={[
                ...store.filterPeriodsReducer.map(period =>
                  excludeDates(period.valid_from)
                )
              ]}
              minDate={typeof data.id === "string" ? new Date() : null}
              placeholderText={
                moment(date).format("YYYY") > 2050
                  ? "Please Pick a valid Date"
                  : ""
              }
            />
          </DateDiv>
          <select
            selected={dropdown[data.id]}
            onChange={sel => handleSelected(sel.target.value)}
            defaultValue={dropdown[data.id]}
            key={uuid()}
          >
            {displayOptions.map(option => (
              <option key={option + uuid()} value={option}>
                {option}
              </option>
            ))}
          </select>

          {dropdown[data.id] === "Weekdays" && (
            <Weekday
              key={uuid()}
              sortdays={sortdays}
              toDisable={true}
              periodId={data}
              checked={checked}
              anytime={anytime}
            />
          )}
          {dropdown[data.id] === "Daily" && (
            <DailyComponent
              key={uuid()}
              daily={daily}
              toDisable={false}
              parentID={data.id}
              weekday={new Date(date).getDay()}
              checked={checked}
              anytime={anytime}
            />
          )}
          <PeriodParent key={uuid()}>
            <h3 style={textStyle}>Mark as: </h3>
            <PeriodType key={uuid()}>
              <input
                type="radio"
                style={{ width: "25px" }}
                value="period"
                checked={data.type === "period"}
                onChange={e => handlePeriodType(e.target.value)}
              />
              <p>Period</p>
              <input
                type="radio"
                style={{ width: "25px" }}
                value="holiday"
                checked={data.type === "holiday"}
                onChange={e => handlePeriodType(e.target.value)}
              />
              <p>Holiday</p>
              {/* </div> */}
            </PeriodType>
          </PeriodParent>
          {data && (
            <CheckBoxes
              key={uuid()}
              date={date}
              checked={checked}
              setChecked={setChecked}
              parentID={data.id}
            />
          )}
          {store.changes.newPeriods.length > 0 &&
            store.changes.newPeriods[0].id === data.id && <SaveNewPeriod />}
          {store.changes.patchPeriods.length > 0 &&
            store.changes.patchPeriods[0].id === data.id && <SaveNewPeriod />}
          {store.messagesReducer === "LOADING" &&
            ((store.changes.patchPeriods.length > 0 &&
              store.changes.patchPeriods[0].id === data.id) ||
              (store.changes.newPeriods.length > 0 &&
                store.changes.newPeriods[0].id === data.id)) && (
              <CircularProgress
                key={uuid()}
                style={{ position: "absolute", left: "50%", top: "30%" }}
              />
            )}
        </CheckinItem>
      </Box>
    </>
  );
};
export default Boxes;

const textStyle = {
  fontSize: "15px",
  margin: "5px 0 10px",
  color: "#333",
  fontWeight: 700
};
