import { setHours, setMinutes } from "date-fns";
export const minStart = reference =>
  reference
    ? setHours(
        setMinutes(new Date(), +reference.end.split(":")[1] + 15),
        +reference.end.split(":")[1] + 15 === 60
          ? +reference.end.split(":")[0] + 1
          : reference.end.split(":")[0]
      )
    : setHours(setMinutes(new Date(), 0), 0);
export const maxStart = endDate =>
  endDate
    ? setHours(
        setMinutes(
          new Date(),
          new Date(endDate).getMinutes() === 0
            ? 45
            : new Date(endDate).getMinutes() - 15
        ),
        new Date(endDate).getMinutes() === 0
          ? new Date(endDate).getHours() - 1
          : new Date(endDate).getHours()
      )
    : setHours(setMinutes(new Date(), 45), 23);

export const minEnd = (reference, startDate) =>
  reference && !startDate
    ? setHours(
        setMinutes(new Date(), +reference.end.split(":")[1] + 30),
        reference.end.split(":")[0]
      )
    : !!startDate
    ? setHours(
        setMinutes(new Date(), new Date(startDate).getMinutes() + 15),
        new Date(startDate).getHours() +
          (new Date(startDate).getMinutes() + 15 === 60 ? 1 : 0)
      )
    : setHours(setMinutes(new Date(), 15), 0);
