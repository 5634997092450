import { useDispatch } from "react-redux";
import * as uuid from "uuid";
import { blankDays } from "../uitlity/constants";
import moment from "moment";

const useWeekday = ({ sortdays, periodId }) => {
  const byDay = { ...blankDays, ...sortdays };
  const dispatch = useDispatch();
  const handleAddTime = (start, end, { weekday, id, check_in_period_id }) => {
    // as in daily componnet api call will be based upon presence of check_in_period_id and id of hours
    // unsaved period
    if (typeof check_in_period_id === "string") {
      if (typeof id === "string") {
        let index = periodId.check_in_hours.findIndex(hour => hour.id === id);
        periodId.check_in_hours[index] = {
          ...periodId.check_in_hours[index],
          begin: moment(new Date(start)).format("HH:mm:ss"),
          end: moment(new Date(end)).format("HH:mm:ss")
        };
      } else {
        if (start === "" || end === "") return;
        periodId.check_in_hours.push({
          begin: moment(new Date(start)).format("HH:mm:ss"),
          end: moment(new Date(end)).format("HH:mm:ss"),
          weekday,
          check_in_period_id,
          id: uuid()
        });
      }
      dispatch({ type: "HOURS", data: [periodId] });
      return;
    }
    // existing period
    if (check_in_period_id) {
      if (typeof id === "number" || typeof id === "string") {
        let index = periodId.check_in_hours.findIndex(hour => hour.id === id);
        periodId.check_in_hours[index] = {
          ...periodId.check_in_hours[index],
          begin: moment(new Date(start)).format("HH:mm:ss"),
          end: moment(new Date(end)).format("HH:mm:ss")
        };
      } else {
        if (!start || !end) return;
        periodId.check_in_hours.push({
          begin: moment(new Date(start)).format("HH:mm:ss"),
          end: moment(new Date(end)).format("HH:mm:ss"),
          weekday,
          check_in_period_id,
          id: uuid()
        });
      }
      dispatch({ type: "HOURS", data: [periodId] });
      return;
    }
  };
  return {
    byDay,
    handleAddTime
  };
};
export default useWeekday;
