import * as filter from "../constants/filterTypes";
import { dateCompare } from "../../uitlity/momentPoly";
const filterPeriodsReducer = (state = [], action) => {
  switch (action.type) {
    case filter.ALL:
      return action.result.periods;
    case filter.UPCOMING:
      return [
        ...action.result.periods.filter(
          period => dateCompare(period.valid_from) >= dateCompare()
        )
      ];
    case filter.PAST:
      return [
        ...action.result.periods.filter(
          period => dateCompare(period.valid_from) < dateCompare()
        )
      ];
    case filter.DATES_RANGE_FILTER:
      return [
        ...action.result.periods.filter(
          period =>
            dateCompare(period.valid_from) >= dateCompare(action.from) &&
            dateCompare(period.valid_from) <= dateCompare(action.to)
        )
      ];
    case "FILTER_FROM":
      return [
        ...action.result.periods.filter(
          period => dateCompare(period.valid_from) >= dateCompare(action.from)
        )
      ];
    case "FILTER_TO":
      return [
        ...action.result.periods.filter(
          period => dateCompare(period.valid_from) <= dateCompare(action.to)
        )
      ];
    case "PERIOD":
      return [
        ...action.result.periods.filter(period => period.type === action.from && dateCompare(period.valid_from) >= dateCompare(action.date_start) &&
            dateCompare(period.valid_from) <= dateCompare(action.date_end))
      ];
    case filter.NO_CHECK_IN:
      return [
        ...action.result.periods.filter(
          period => period.check_in_hours.length === 0 && dateCompare(period.valid_from) >= dateCompare(action.date_start) &&
            dateCompare(period.valid_from) <= dateCompare(action.date_end)
        )
      ];
    default:
      return state;
  }
};

export default filterPeriodsReducer;
