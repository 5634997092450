const fetchingLogin = async body => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Pragma: "no-cache",
      Accept: "application/json"
    },
    body: JSON.stringify({ username: body.email, password: body.password })
  };
  return await fetch("https://test-api.inbooma.net/api/users/token", options);
};
export default fetchingLogin;
