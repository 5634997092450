import * as actions from "../constants/actionTypes";
const initalState = {
  success: false,
  error: false,
  failure: false
};
const loginReducer = (state = initalState, action) => {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        success: action.payload,
        failure: false,
        error: false
      };
    case actions.LOGIN_FAILED:
      return {
        ...state,
        failure: action.payload,
        success: false,
        error: false
      };
    case action.LOGIN_ERROR:
      return {
        ...state,
        failure: false,
        success: false,
        error: action.payload
      };
    default:
      return state;
  }
};
export default loginReducer;
