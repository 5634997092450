import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import * as uuid from "uuid";
import useSaveNewPeriod from "../hooks/useSaveNewPeriod";
import {
  Header2,
  List,
  ListItem,
  Span,
  AddButton
} from "../styled_components/exporter";
import { holidays } from "../uitlity/constants";
import { germanDate } from "../uitlity/momentPoly";
import moment from "moment";
const customStyles = {
  content: {
    top: "40%",
    left: "20%",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    overflow: "auto",
    height: "60vh",
    right: "auto",
    zIndex: "99"
  }
};
const HolidayModal = ({ isOpen, setIsOpen, location }) => {
  const [readyToDispatch, setReadyToDispatch] = useState(false);
  const dispatch = useDispatch();
  const { periods } = useSelector(data => data);
  const filteredHolidays = holidays.filter(
    holiday =>
      periods
        .map(x => moment(x.valid_from).format("YYYY-MM-DD"))
        .indexOf(moment(Object.values(holiday)[0]).format("YYYY-MM-DD")) < 0
  );
  const { holidayHandler } = useSaveNewPeriod();
  const addAndSave = (e, date, place) => {
    let hours =
      periods.filter(
        period =>
          moment(period.valid_from).format("YYYY-MM-DD") <
          moment(date).format("YYYY-MM-DD")
      ) || [];
    if (hours.length)
      hours = hours.slice(-1)[0].check_in_hours.map(hour => {
        return { ...hour, check_in_period_id: "", id: "" };
      });
    let i = 0;
    let a = holidays.map(h => moment(Object.values(h)[0]).format("YYYY-MM-DD"));
    while (
      a.indexOf(
        moment(date)
          .add(i, "days")
          .format("YYYY-MM-DD")
      ) > -1
    ) {
      dispatch({
        type: "ADD_PERIOD",
        period: {
          location_id: place,
          check_in_hours: [],
          valid_from: moment(date)
            .add(i, "days")
            .format("YYYY-MM-DD HH:mm:ss"),
          type: "holiday",
          id: "fakeID-" + uuid()
        }
      });
      i++;
    }
    dispatch({
      type: "ADD_PERIOD",
      period: {
        location_id: place,
        check_in_hours: hours,
        valid_from: moment(date)
          .add(i, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
        type: "period",
        id: "fakeID-" + uuid()
      }
    });

    setReadyToDispatch(true);
    setIsOpen(false);
  };
  useMemo(() => {
    if (readyToDispatch) {
      holidayHandler();
      setReadyToDispatch(false);
    }
    // eslint-disable-next-line
  }, [readyToDispatch]);
  useMemo(() => {
    document.addEventListener("keydown", e => {
      if (e.keyCode === 27) {
        setIsOpen(false);
      }
    });
    return () =>
      document.removeEventListener("keydown", () => console.log("removed"));
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Modal isOpen={isOpen} ariaHideApp={false} style={customStyles}>
        <AddButton
          onClick={() => setIsOpen(false)}
          style={{ position: "absolute", right: "3rem" }}
        >
          X
        </AddButton>
        <List style={{ position: "relative", top: "3rem" }}>
          {filteredHolidays.map(x => (
            <ListItem
              key={uuid()}
              style={{
                margin: "10px 0",
                display: "flex",
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              <Span style={{ fontSize: "1rem" }}>
                {Object.keys(x)[0]} - {germanDate(Object.values(x)[0] )}
              </Span>{" "}
              <AddButton
                onClick={e => addAndSave(e, Object.values(x)[0], location)}
                style={{ position: "relative", padding: "5px 15px" }}
              >
                Add Holiday Period
              </AddButton>
            </ListItem>
          ))}
        </List>
      </Modal>{" "}
    </>
  );
};
export default HolidayModal;
