import { combineReducers } from "redux";

import periods from "./periodsReducer";
import citiesReducer from "./citiesReducer";
import filterPeriodsReducer from "./filterPeriodsReducer";
import loginReducer from "./loginReducer";
import messagesReducer from "./messagesReducer";
import changes from "./changesReducer";
const combinedReducers = combineReducers({
  periods,
  citiesReducer,
  filterPeriodsReducer,
  loginReducer,
  messagesReducer,
  changes
});

export default combinedReducers;
