import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { getYear } from "../uitlity/momentPoly";
//add error for invalid periods
const useSaveNewPeriod = () => {
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const { changes: periods, periods: allPeriods } = useSelector(data => data);
  useEffect(() => {
    setMessage("");
  }, []);
  const cancelChanges = e => {
    e.preventDefault();
    if (periods.patchPeriods.length > 0) {
      dispatch({
        type: "PERIODS",
        link: `https://test-api.inbooma.net/api/check_in_periods?location_id=${periods.patchPeriods[0].location_id}&sort=valid_from&direction=asc`,
        method: "GET"
      });
    } else {
      dispatch({
        type: "PERIODS",
        link: `https://test-api.inbooma.net/api/check_in_periods?location_id=${periods.newPeriods[0].location_id}&sort=valid_from&direction=asc`,
        method: "GET"
      });
    }
    dispatch({ type: "CLEAR", periods });
  };
  const clickHandler = e => {
    if (e) e.stopPropagation();
    let patched = [];
    let stripLast = allPeriods.filter(period => typeof period.id !== "string");
    if (periods.patchPeriods.length > 0) {
      let single = periods.patchPeriods[0];
      single = {
        ...single,
        check_in_hours: [
          ...single.check_in_hours.filter(
            s => s.begin.length > 0 && s.end.length > 0
          )
        ]
      };

      patched.push(single);
    }
    if (periods.newPeriods.length > 0) {
      let newPeriod = periods.newPeriods[0];
      if (getYear(newPeriod.valid_from) > 2100) {
        return setMessage(
          "Please Pick the valid date and fill up all weekdays"
        );
      } else {
        setMessage("");

        newPeriod = {
          ...newPeriod,
          check_in_hours: [
            ...newPeriod.check_in_hours.filter(
              s => s.begin.length > 0 && s.end.length > 0
            )
          ]
        };
      }
      if (periods.newPeriods.length > 0) {
        for (let i = 0; i < stripLast.length; i++) {
          if (comparison(stripLast[i], newPeriod)) {
            alert("Can't save period with exactly same values");
            return;
          }
        }
        newPeriod.id = "";
        for (let l of newPeriod.check_in_hours) {
          if (typeof l.id === "string") {
            l.id = "";
          }
          if (typeof l.check_in_period_id === "string") {
            l.check_in_period_id = "";
          }
        }
        dispatch({
          type: "SUBMIT",
          payload: [newPeriod]
        });
      }
    }
    if (patched.length > 0) {
      let stripPatched = allPeriods.filter(
        period => period.id !== patched[0].id
      );
      for (let i = 0; i < stripPatched.length; i++) {
        if (comparison(stripPatched[i], periods.patchPeriods[0])) {
          alert("Compare setting with before an following periods");
          return;
        }
      }
      for (let l of patched[0].check_in_hours) {
        if (typeof l.id === "string") {
          l.id = "";
        }
      }
      dispatch({
        type: "PATCH_PERIOD",
        payload: patched
      });
    }
  };
  const holidayHandler = () => {
    let newPeriods = periods.newPeriods.map(period => {
      return { ...period, id: "" };
    });
    dispatch({
      type: "SUBMIT",
      payload: newPeriods
    });
  };
  return { message, clickHandler, cancelChanges, holidayHandler };
};

export default useSaveNewPeriod;

const sortingA = per => {
  const sortdays = {};
  per.check_in_hours.map(hour => {
    if (!sortdays[hour.weekday]) {
      sortdays[hour.weekday] = [];
      sortdays[hour.weekday].push(hour);
    } else {
      sortdays[hour.weekday].push(hour);
    }
    return hour;
  });
  return sortdays;
};
const comparison = (period, newPer) => {
  if (
    newPer.check_in_hours.length === 0 ||
    period.check_in_hours.length !== newPer.check_in_hours.length
  )
    return false;
  let newPeriod = sortingA(newPer);
  let allTrue = [];
  let oldPeriod = sortingA(period);
  for (let val in oldPeriod) {
    for (let i = 0; i < oldPeriod[val].length; i++) {
      if (!newPeriod[val]) return false;
      if (
        hoursAndMinutesCompare(
          oldPeriod[val][i].begin,
          newPeriod[val][i].begin
        ) &&
        hoursAndMinutesCompare(oldPeriod[val][i].end, newPeriod[val][i].end)
      ) {
        allTrue.push(true);
      }
    }
  }
  return allTrue.length === period.check_in_hours.length;
};

const hoursAndMinutesCompare = (first = undefined, second = undefined) => {
  let firstArr = first.split(":");
  let secondArr = second.split(":");
  const hoursAndMinutes = time =>
    time
      .split(":")
      .slice(0, 2)
      .join(":");
  if (
    (hoursAndMinutes(first) === "00:00" &&
      hoursAndMinutes(second) === "00:00") ||
    (hoursAndMinutes(first) === "23:45" && hoursAndMinutes(second) === "23:45")
  )
    return false;
  return firstArr[0] === secondArr[0] && firstArr[1] === secondArr[1];
};
