import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faUser, faKey } from "@fortawesome/free-solid-svg-icons";
import { logo } from "../images/imageExporter";
import useLogin from "../hooks/useLogin";
import {
  LoginTopDiv,
  LoginWrap,
  Column,
  LoginLogo,
  LoginForm,
  Label,
  Input,
  Text,
  SwitchLabel,
  InputCheck,
  Toggler,
  TextFooter,
  LinkStyled,
  SubmitButton
} from "../styled_components/styled_login/index";
import "bootstrap/scss/bootstrap.scss";
const LoginScreen = props => {
  const { error, setCredentials, credentials, handleSubmit } = useLogin(props);

  return (
    <>
      <LoginTopDiv>
        <LoginWrap>
          <Column>
            <LoginLogo>
              <img src={logo} alt="logo" />
            </LoginLogo>
            <LoginForm>
              <span className="text-danger">{error}</span>
              <form action="#">
                <Label>
                  <FontAwesomeIcon icon={faUser} />
                  <Input
                    type="text"
                    required
                    onChange={e =>
                      setCredentials({ ...credentials, email: e.target.value })
                    }
                    placeholder="Email address or user name"
                  />
                </Label>
                <Label>
                  <FontAwesomeIcon icon={faKey} />
                  <Input
                    type="password"
                    required
                    onChange={e =>
                      setCredentials({
                        ...credentials,
                        password: e.target.value
                      })
                    }
                    placeholder="Password"
                  />
                </Label>
                <div>
                  <Text>Stay logged in?</Text>
                  <SwitchLabel>
                    <InputCheck
                      type="checkbox"
                      checked
                      onChange={() =>
                        setCredentials({
                          ...credentials,
                          checked: !credentials.checked
                        })
                      }
                    />
                    <Toggler checked={credentials.checked} />
                  </SwitchLabel>
                </div>
                <SubmitButton type="submit" onClick={e => handleSubmit(e)}>
                  Login
                </SubmitButton>
              </form>
            </LoginForm>
            <div>
              <TextFooter>
                <LinkStyled href="change-password.php">
                  Change password
                </LinkStyled>{" "}
                | Made with <FontAwesomeIcon icon={faHeart} /> in Leipzig
              </TextFooter>
            </div>
          </Column>
        </LoginWrap>
      </LoginTopDiv>
    </>
  );
};

export default LoginScreen;
