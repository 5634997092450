import styled from "styled-components";

export const LoginTopDiv = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 15px;
`;
export const LoginWrap = styled(LoginTopDiv)`
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  @media only screen and (max-width: 767.98px) {
    height: inherit;
    align-items: flex-start;
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;
export const Column = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
`;
export const SubmitButton = styled.button`
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #eb6a3a;
  border-radius: 4px;
  display: block;
  background-color: #eb6a3a;
  color: #fff;
  transition: all 0.2s;
`;

export const LoginForm = styled.div`
  text-align: left;
  display: inline-block;
  width: 100%;
  max-width: 600px;
  padding: 40px 35px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background-color: #fff;
  @media only screen and (max-width: 767.98px) {
    padding: 30px 15px;
  }
`;

export const LoginLogo = styled.div`
  margin-bottom: 20px;
`;

export const Label = styled.label`
  background-color: #fff;
  display: block;
  position: relative;
  margin-bottom: 35px;
  border-radius: 3px;
`;

export const Input = styled.input`
  width: 100%;
  display: block;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #e3e3e3;
  height: 35px;
  line-height: 35px;
  padding-left: 10px;
  padding-right: 40px;
  font-size: 16px;
  transition: all 0.2s;

  &:focus {
    border-color: #eb6a3a;
  }
`;
export const InputCheck = styled(Input)`
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
  visibility: hidden;
  z-index: -1;
  position: absolute;
`;
export const SwitchLabel = styled(Label)`
  position: relative;
  width: 48px;
  height: 24px;
  margin-bottom: 0;
  float: right;
`;
export const Toggler = styled.span`
  &::before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: ${props => (props.checked ? "4px" : "30px")};
    bottom: 4px;
    background-color: #fff;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    border-radius: 3px;
  }

  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => (props.checked ? "#5d687e" : "#eb6a3a")};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 3px;
`;

export const Text = styled.p`
  font-size: 15px;
  margin-bottom: 20px;
  display: inline-block;
`;
export const TextFooter = styled(Text)`
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 15px;
`;

export const LinkStyled = styled.a`
  color: #eb6a3a;
  &:active,
  &:focus,
  &:hover,
  &:visited {
    text-decoration: none;
    color: #eb6a3a;
    outline: 0 !important;
  }
`;
