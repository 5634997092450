const addPeriodFetch = ({ link, method, body }) => {
  let token = localStorage.getItem("inboomatoken");
  let options = {
    method,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(body)
  };
  return fetch(link, options)
    .then(response => {
      return response.json();
    })
    .then(result => result);
};

export default addPeriodFetch;
