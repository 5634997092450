import styled from "styled-components";

export const Day = styled.h3`
  font-size: 15px;
  margin: 5px 0 10px;
  color: #333;
  font-weight: 700;
`;

export const HoursWeek = styled.div`
  display: flex;
  align-items: center;
`;
