import { useState, useEffect } from "react";
import { setHours, setMinutes } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
const useWeeklyDropdown = ({ hourly, handleAddTime }) => {
  const { periods } = useSelector(data => data);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(
    hourly.begin && hourly.begin.length > 0
      ? setHours(
          setMinutes(new Date(), hourly.begin.split(":")[1]),
          hourly.begin.split(":")[0]
        )
      : ""
  );
  const [endDate, setEndDate] = useState(
    hourly.end && hourly.end.length
      ? setHours(
          setMinutes(new Date(), hourly.end.split(":")[1]),
          hourly.end.split(":")[0]
        )
      : ""
  );
  const [changed, setChanged] = useState({ start: false, end: false });
  const handleStartDate = date => {
    setStartDate(date);
    setChanged({ ...changed, start: true });
  };
  const handleEndDate = date => {
    setEndDate(date);
    setChanged({ ...changed, end: true });
  };
  const handleDeleteHours = (id, parentId) => {
    let forUpdate = periods.filter(period => period.id === parentId)[0];
    forUpdate.check_in_hours = [
      ...forUpdate.check_in_hours.filter(hour => hour.id !== id)
    ];
    dispatch({ type: "DELETE_TIME", data: [forUpdate], id });
  };
  useEffect(() => {
    // if both start and end hours are changed handleAddTime action will be inhourlyoked
    if (changed.start || changed.end) {
      handleAddTime(startDate, endDate, hourly);
      setChanged({ ...changed, start: false, end: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changed.start, changed.end]);
  return {
    startDate,
    handleStartDate,
    endDate,
    handleEndDate,
    handleDeleteHours
  };
};

export default useWeeklyDropdown;
