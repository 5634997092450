import * as actionTypes from "../constants/actionTypes";
import { dateManipulation } from "../../uitlity/momentPoly";
const periods = (filters = [], action) => {
  switch (action.type) {
    case actionTypes.PERIODS_LIST:
      return [...action.response.data];
    case actionTypes.ADD_NEW_PERIOD:
      return [...filters, action.period].sort((current, next) =>
        dateManipulation(current.valid_from) > dateManipulation(next.valid_from)
          ? 1
          : dateManipulation(next.valid_from) >
            dateManipulation(current.valid_from)
          ? -1
          : 0
      );
    case actionTypes.ADD_HOURS:
      let getIndex = filters.findIndex(
        period => period.id === action.data[0].id
      );
      return [...filters.slice(0, getIndex), action.data[0]].concat(
        filters.slice(getIndex + 1)
      );
    case "DELETE_HOURS":
      let delIndex = filters.findIndex(
        period => period.id === action.data[0].id
      );
      return [...filters.slice(0, delIndex), action.data[0]].concat(
        filters.slice(delIndex + 1)
      );
    case actionTypes.DELETE_PERIOD:
      return [...filters.filter(period => period.id !== action.id)];
    case "SUBMIT_NEW_PERIOD":
      return [
        ...filters.filter(period => typeof period.id !== "string"),
        action.period
      ].sort((current, next) =>
        dateManipulation(current.valid_from) > dateManipulation(next.valid_from)
          ? 1
          : dateManipulation(next.valid_from) >
            dateManipulation(current.valid_from)
          ? -1
          : 0
      );
    default:
      return filters;
  }
};

export default periods;
