import { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { makeTimeStamp } from "../uitlity/makeTimes";
import moment from "moment";
import * as uuid from "uuid";
const useBoxes = ({ data, setDropdown, dropdown }) => {
  const [date, setDate] = useState(moment(data.valid_from));
  const [checked, setChecked] = useState({
    checkIn: data.check_in_outside_hours,
    checkOut: data.check_out_outside_hours
  });
  const [anytime, setAnyTime] = useState(false);
  const dispatch = useDispatch();
  const store = useSelector(data => data, shallowEqual);
  const [noClick, setNoClick] = useState(false);
  useEffect(() => {
    if (
      store.changes.newPeriods.length === 0 &&
      store.changes.patchPeriods.length === 0
    )
      return setNoClick(false);
    if (
      (store.changes.newPeriods.length > 0 &&
        store.changes.newPeriods[0].id !== data.id) ||
      (store.changes.patchPeriods.length > 0 &&
        store.changes.patchPeriods[0].id !== data.id)
    )
      return setNoClick(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.changes.newPeriods, store.changes.patchPeriods, data]);
  // by default box model is displayed in weekly list if there is any check in hour
  const handlePeriodType = val => {
    data.type = val;
    dispatch({ type: "HOURS", data: [data] });
    return;
  };
  const handleSelected = val => {
    if (val === "No Check-In / No Booking" && data.check_in_hours.length > 0) {
      if (window.confirm("Do you to make this day as non working?")) {
        const id = data.check_in_hours.map(hour => hour.id);
        data.check_in_hours = [];
        dispatch({ type: "DELETE_TIME", data, id });
      } else {
        return;
      }
    }
    setDropdown({ ...dropdown, [data.id]: val });
  };
  useEffect(() => {
    if (!dropdown[data.id]) {
      if (
        data.check_in_hours.every(testArray) &&
        data.check_in_hours.length > 0
      ) {
        setDropdown({ ...dropdown, [data.id]: "Check-In at anytime" });
      } else {
        setDropdown({
          ...dropdown,
          [data.id]:
            data.check_in_hours.length > 0
              ? "Weekdays"
              : "No Check-In / No Booking"
        });
      }
    }
    if (dropdown[data.id] === "Check-In at anytime") {
      testAndChange(data, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown]);

  useEffect(() => {
    if (data.check_in_hours.every(testArray)) {
      setAnyTime(true);
    }
    if (
      data.check_in_outside_hours !== checked.checkIn ||
      data.check_out_outside_hours !== checked.checkOut
    ) {
      data.check_in_outside_hours = checked.checkIn;
      data.check_out_outside_hours = checked.checkOut;
      dispatch({ type: "HOURS", data: [data] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, data]);
  const sortdays = {};
  const daily = data.check_in_hours.filter(
    day => +day.weekday % 7 === new Date(date).getDay()
  );
  const handleChange = date => {
    setDate(date);
    data.valid_from = moment(new Date(date)).format("YYYY-MM-DD HH:mm:ss");
    dispatch({ type: "HOURS", data: [data] });
  };
  const handleCopy = e => {
    const id = "fakeID-" + uuid();
    const copiedData = data.check_in_hours.map(hour => {
      return {
        begin: hour.begin,
        end: hour.end,
        weekday: hour.weekday,
        id: "fakeID" + uuid(),
        check_in_period_id: id
      };
    });
    e.preventDefault();
    dispatch({
      type: "ADD_PERIOD",
      period: {
        location_id: data.location_id,
        valid_from: makeTimeStamp(),
        id,
        check_in_hours: copiedData,
        type: "period",
        check_in_outside_hours: checked.checkIn,
        check_out_outside_hours: checked.checkOut
      }
    });
  };
  const handleDelete = e => {
    e.preventDefault();
    dispatch({
      type: "DELETE",
      id: data.id,
      period: store,
      method: "DELETE",
      link: `https://test-api.inbooma.net/api/check_in_periods/${data.id}`
    });
  };
  data.check_in_hours.map(hour => {
    if (!sortdays[hour.weekday]) {
      sortdays[hour.weekday] = [];
      sortdays[hour.weekday].push(hour);
    } else {
      sortdays[hour.weekday].push(hour);
    }
    return hour;
  });
  return {
    setChecked,
    daily,
    handleDelete,
    handleChange,
    handleCopy,
    date,
    checked,
    sortdays,
    handlePeriodType,
    store,
    noClick,
    handleSelected,
    anytime
  };
};

const testArray = hour =>
  hour.end
    .split(":")
    .slice(0, 2)
    .join(":") === "23:59" &&
  hour.begin
    .split(":")
    .slice(0, 2)
    .join(":") === "00:00";

const testAndChange = (data, cb) => {
  if (!data.check_in_hours.every(testArray)) {
    data.check_in_hours.forEach(hour => {
      hour.begin = "00:00:00";
      hour.end = "23:59:59";
    });
    cb({ type: "HOURS", data: [data] });
  } else if (data.check_in_hours.length === 0) {
    for (let i = 0; i < 7; i++) {
      data.check_in_hours[i] = {
        weekday: i + 1,
        begin: "00:00:00",
        end: "23:59:59"
      };
    }
    cb({ type: "HOURS", data: [data] });
  }
};

export default useBoxes;
