import { useSelector, useDispatch } from "react-redux";
import { makeTimeStamp } from "../uitlity/makeTimes";
import * as uuid from "uuid";

const useAddNewPeriod = ({ currentCity }) => {
  const dispatch = useDispatch();
  const periods = useSelector(data => data);
  // upong clicking new period template will be added to the dom, current version allows only one period to be present before being saved
  const handleClick = e => {
    if (
      currentCity === "" ||
      periods.changes.newPeriods.length > 0 ||
      periods.changes.patchPeriods.length > 0
    )
      return;
    e.preventDefault();
    dispatch({
      type: "ADD_PERIOD",
      period: {
        location_id: periods.citiesReducer.filter(
          city => city.id === currentCity
        )[0].id,
        check_in_hours: [],
        valid_from: makeTimeStamp(periods.changes.newPeriods.slice(-1)[0]),
        type: "period",
        id: "fakeID-" + uuid()
      }
    });
  };
  return {
    handleClick,
    periods
  };
};
export default useAddNewPeriod;
