import { useState } from "react";
import Authenticate from "../uitlity/auth";
import fecthingLogin from "../store/api/fetchLogin";
const useLogin = props => {
  const [error, setError] = useState("");
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    checked: false
  });
  const handleSubmit = e => {
    e.preventDefault();
    fecthingLogin(credentials)
      .then(res => res.json())
      .then(r => {
        if (r.success) {
          localStorage.setItem("inboomatoken", r.data.token);
          localStorage.setItem("inboomauser", JSON.stringify(r.user));
          setError("");
          // upon succesful login user will be redirected to the root page
          Authenticate.login(() => {
            props.history.push("/");
          });

          props.history.push("/");
        } else {
          if (r.data.message.length > 0) {
            setError(r.data.message);
          } else {
            setError("Something went wrong, please try again later");
          }
        }
        return;
      })
      .catch(e => console.log(e));
  };
  return { error, setCredentials, credentials, handleSubmit };
};
export default useLogin;
