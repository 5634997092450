import React from "react";
import "../styles/myDropDown.css";
import * as uuid from "uuid";
import { Header2 } from "../styled_components/exporter";
import useLocations from "../hooks/useLocations";
import MyDropDown from "../uitlity/myDropDown";
const LocationsDropDown = ({ currentCity, setCurrentCity }) => {
  const { result, handleChange } = useLocations({
    currentCity,
    setCurrentCity
  });
  return (
    <>
      <Header2>Location</Header2>
      {result.citiesReducer.length > 0 && (
        <MyDropDown
          className="adjust"
          controlClassName="noBorders-noBackground"
          key={uuid()}
          options={result.citiesReducer.map((city, i) => {
            return { value: city.id, label: city.name, arrayId: i };
          })}
          onChange={handleChange}
          value={
            currentCity === ""
              ? ""
              : result.citiesReducer.filter(city => city.id === currentCity)[0]
                  .name
          }
          placeholder="Select Location"
        />
      )}
    </>
  );
};

export default LocationsDropDown;
