import * as uuid from "uuid";

const blankCheckInHours = (start = "", end = "", weekday = 0, periodId) => {
  return {
    check_in_period_id: periodId,
    weekday: weekday,
    begin: start,
    end: end,
    id: uuid()
  };
};

export default blankCheckInHours;
