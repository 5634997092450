import React, { useState, useMemo } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as uuid from "uuid";
import { CheckInPeriods, CheckInTimesPanel } from "./components/exporter";
import "bootstrap/scss/bootstrap.scss";
// import Authenticate from "./uitlity/auth";
import styled from "styled-components";
const App = props => {
  const dispatch = useDispatch();
  const [dropdown, setDropdown] = useState({});
  useMemo(() => {
    // initial call that retrieves list of location upon component mounting
    dispatch({
      type: "CITIES",
      link:
        "https://test-api.inbooma.net/api/locations?find=short&limit=999&page=1&sort=name&direction=asc",
      method: "GET"
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // current view will only work for user that has the role of admin
  return (
    <>
      <Router>
        <Route exact path="/">
          <Main className="container-fluid checkin p-0">
            <>
              <div className="row mx-0">
                <Nav className="col-lg-2 py-5" id="sideBar">
                  <CheckInTimesPanel key={uuid()} />
                </Nav>
                <div className="col-lg-10">
                  <CheckInPeriods
                    key={uuid()}
                    dropdown={dropdown}
                    setDropdown={setDropdown}
                  />
                </div>
              </div>
            </>
          </Main>
        </Route>

        {/* <Route exact path="/">
         <Navigation />
          </Route> */}
      </Router>
    </>
  );
};

export default App;

const Main = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 !important;
`;
const Nav = styled.div`
  padding-left: 20px;

  position: relative;

  margin-bottom: 3px;

  color: #5d687e;

  font-family: Open Sans, sans-serif;
`;
