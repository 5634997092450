import styled from "styled-components";

export const Dash = styled.p`
  background-color: #fff;
  height: 40px;
  display: flex;
  width: 100%;
  font-size: 15px;
  margin-bottom: 15px;
  align-items: center;
`;
