import React from "react";
import DatePicker from "react-datepicker";
import de from "date-fns/locale/de";
import * as uuid from "uuid";
import Popup from "reactjs-popup";
import { Button } from "../styled_components/styled_box/";
import { subDays } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faTimes,
  faEllipsisH
} from "@fortawesome/free-solid-svg-icons";
import {
  List,
  ListItem,
  Div,
  Span,
  Header2
} from "../styled_components/exporter";
import {
  Dot,
  ListDiv,
  AddNew
} from "../styled_components/styled_check_in_times/index";
import { LocationsDropDown, AddNewPeriod } from "./exporter";
import { HolidayModal } from "../ui_components/uiExporter";
import "react-datepicker/dist/react-datepicker.css";
import { times } from "../uitlity/constants";
import useChekinTimesPanel from "../hooks/useCheckinTimesPanel";
const CheckInTimesPanel = () => {
  const {
    handleChangeFrom,
    handleChangeTo,
    handleClick,
    handlePeriod,
    setCurrentCity,
    date,
    period,
    currentCity,
    store,
    isOpen,
    setIsOpen,
    clickHandler
  } = useChekinTimesPanel();
  return (
    <>
      <Div>
        <List id="filters">
          {times.map(time => (
            <ListItem key={time} onClick={() => handleClick(time)}>
              <Dot show={time === date.day}>
                <FontAwesomeIcon icon={faCircle} />
              </Dot>
              <ListDiv>{time}</ListDiv>
            </ListItem>
          ))}
        </List>
        <hr />
      </Div>
      <Div>
        <Div>
          <HolidayModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            location={currentCity}
          />
          <Header2>Dates</Header2>
          <List>
            <ListItem>
              {store.filterPeriodsReducer.length > 0 ? (
                <>
                  <DatePicker
                    className="noBorders-noBackground"
                    dateFormat="dd.MM.y"
                    selected={
                      new Date(date.from).getFullYear() === 1970
                        ? null
                        : date.from
                    }
                    onChange={date => handleChangeFrom(date)}
                    onKeyDown={e => {
                      if (e.keyCode === 8) e.preventDefault();
                    }}
                    placeholderText="From ..."
                    locale={de}
                  />
                  <Span onClick={() => handleChangeFrom(null)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </Span>
                </>
              ) : (
                <>
                  <DatePicker
                    className="noBorders-noBackground"
                    dateFormat="dd.MM.y"
                    selected={
                      new Date(date.from).getFullYear === 1970
                        ? null
                        : date.from
                    }
                    onChange={date => handleChangeFrom(date)}
                    onKeyDown={e => {
                      if (e.keyCode === 8) e.preventDefault();
                    }}
                    placeholderText="From ..."
                    locale={de}
                  />
                  <Span onClick={() => handleChangeFrom(null)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </Span>
                </>
              )}
            </ListItem>
            <ListItem>
              {store.filterPeriodsReducer.length > 0 ? (
                <>
                  <DatePicker
                    className="noBorders-noBackground"
                    dateFormat="dd.MM.y"
                    selected={
                      date.to > date.from ||
                      new Date(date.to).getFullYear() === 1970
                        ? new Date(date.to).getFullYear() === 1970
                          ? null
                          : date.to
                        : date.from
                    }
                    onChange={date => handleChangeTo(date)}
                    onKeyDown={e => {
                      if (e.keyCode === 8) e.preventDefault();
                    }}
                    minDate={
                      date.from
                        ? subDays(new Date(date.from), 0)
                        : subDays(new Date(), 0)
                    }
                    placeholderText=" ... To"
                    locale={de}
                  />
                  <Span onClick={() => handleChangeTo(null)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </Span>
                </>
              ) : (
                <>
                  <DatePicker
                    className="noBorders-noBackground"
                    dateFormat="dd.MM.y"
                    selected={
                      date.to > date.from ||
                      new Date(date.to).getFullYear() === 1970
                        ? new Date(date.to).getFullYear() === 1970
                          ? null
                          : date.to
                        : date.from
                    }
                    onChange={date => handleChangeTo(date)}
                    onKeyDown={e => {
                      if (e.keyCode === 8) e.preventDefault();
                    }}
                    minDate={
                      date.from > 0
                        ? subDays(new Date(date.from), 0)
                        : subDays(new Date(), 0)
                    }
                    placeholderText=" ... To"
                    locale={de}
                  />
                  <Span onClick={() => handleChangeTo(null)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </Span>
                </>
              )}
            </ListItem>
          </List>
        </Div>
        <Div>
          <List>
            {["Holiday", "Period", "no Check-In/No Booking"].map(time => (
              <ListItem key={time} onClick={() => handlePeriod(time)}>
                <Dot show={time.toLowerCase() === period.toLowerCase()}>
                  <FontAwesomeIcon icon={faCircle} />
                </Dot>
                <ListDiv>{time}</ListDiv>
              </ListItem>
            ))}
          </List>
        </Div>
        <Div>
          <LocationsDropDown
            currentCity={currentCity}
            setCurrentCity={setCurrentCity}
          />
        </Div>
          <AddNew style={{position: "relative"}}>
            <AddNewPeriod currentCity={currentCity} wideButton={true} />
            <Popup
              key={uuid()}
              id="test-id"
              style={{ padding: 0 }}
              trigger={
                <Button style={{position: "absolute",left: "80%",top: "5px",zIndex: "10"}}>
                  <FontAwesomeIcon icon={faEllipsisH} />
                </Button>
              }
              position="top right"
            >
              {() => (
                <Header2 id="holy" onClick={clickHandler} >
                  Select from holidays
                </Header2>
              )}
            </Popup>
          </AddNew>
      </Div>
    </>
  );
};
export default CheckInTimesPanel;
