const initalState = {
  newPeriods: [],
  patchPeriods: []
};
const changes = (state = initalState, action) => {
  switch (action.type) {
    case "NEW_PERIOD":
      return {
        ...state,
        newPeriods: [...state.newPeriods, action.data],
        changes: state.newPeriods.length + state.patchPeriods.length
      };
    case "ADD_HOURS_NEW_PERIOD":
      return {
        ...state,
        newPeriods: [
          ...state.newPeriods.filter(period => period.id !== action.data[0].id),
          action.data[0]
        ]
      };
    case "REMOVE_CHANGES":
      return {
        ...state,
        newPeriods: [
          ...state.newPeriods.filter(period => period.id !== action.id)
        ]
      };
    case "DELETE_CHANGES":
      return {
        ...state,
        patchPeriods: [
          ...state.patchPeriods.filter(period => period.id !== action.id)
        ]
      };
    case "UPDATE_PERIOD":
      return {
        ...state,
        patchPeriods: [
          ...state.patchPeriods.filter(
            period => period.id !== action.data[0].id
          ),
          action.data[0]
        ]
      };
    case "CLEAR_NEW_PERIODS":
      return {
        ...state,
        newPeriods: []
      };
    case "CLEAR_PATCHES":
      return { ...state, patchPeriods: [] };
    default:
      return state;
  }
};
export default changes;
