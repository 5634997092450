import styled from "styled-components";

export const Box = styled.div`
  max-width: ${props => props.boxWidth}px;
  min-width: ${props => props.boxWidth}px;
  min-height: 90vh;
  pointer-events: ${props => (props.noClick ? "none" : "")};
  opacity: ${props => (props.noClick ? "0.1" : "1")};
  padding: 10px;
  margin: 0 ${props => props.margin}px;
`;

export const Button = styled.button`
  background-color: #fff;
  border: 1px solid #d3d3d3;
  top: 15px;
  left: -15px;
  position: relative;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;
export const Divider = styled.div`
  height: 0;
  border-top: 1px solid #e3e3e3;
  margin: 4px 0;
`;
export const CopyDelete = styled.div`
  line-height: 1.85rem;
  width: 100% !important;
  color: #5d687e;
  &:hover {
    cursor: pointer;
  }
`;
export const PopupParent = styled.div`
  width: 100% !important;
  padding: 0 !important;
`;

export const CheckinItem = styled.div`
  border-radius: 3px;
  padding: 0 15px 15px;
  border: 1px solid;
  border-color: ${props => (props.holiday ? "#eb6a3a" : "#d3d3d3")};
  background-color: #f9f9fc;
  height: 100%;
  min-height: 90vh;
  opacity: ${props => (props.past ? "0.4" : "1")};
  position: relative;
`;
export const CheckInH2 = styled.h2`
  font-size: 14px;
  position: relative;
  top: -15px;
  display: inline-block;
  background-color: #5d687e;
  color: #fff;
  padding: 5px 10px;
  margin: 0;
  border-radius: 3px;
`;
export const PeriodType = styled.div`
  border-radius: 3px;
  background-color: #fff;
  height: 40px;
  display: block;
  width: 100%;
  font-size: 15px;
  margin-bottom: 15px;
  transition: all 0.2s;
  border: 1px solid #e3e3e3;
  display: flex;
  justify-content: space-evenly;
  padding-top: 15px;
  align-items: center;
`;
export const PeriodParent = styled.div`
  font-size: 15px;
  margin: 5px 0 10px;
  color: #333;
  font-weight: 700;
`;
export const DateDiv = styled.div`
  position: relative;
  &:after {
    content: "";
    display: inline-block;
    background-image: url("../../images/calendar.svg");
    background-size: contain;
    background-position: 50%;
    height: 20px;
    width: 20px;
    position: absolute;
    z-index: 2;
    right: 10px;
    top: 10px;
    opacity: 0.4;
  }

  & > input {
    border-radius: 3px;
    background-color: #fff;
    height: 40px;
    display: block;
    width: 100%;
    font-size: 15px;
    margin-bottom: 15px;
    transition: all 0.2s;
    border: 1px solid #e3e3e3;
  }
`;
