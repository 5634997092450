import styled from "styled-components";

export const List = styled.ul`
  list-style: none;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;
