import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import filtering from "../uitlity/filtering";
const useCheckinTimesPanel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const store = useSelector(data => data);
  const [currentCity, setCurrentCity] = useState(
    store.filterPeriodsReducer.length > 0 ?
    store.filterPeriodsReducer[0].location_id :
    ""
  );
  const [period, setPeriod] = useState("");
  const [date, setDate] = useState({
    from: null,
    to: null,
    day: "ALL"
  });
  const clickHandler = () => {
    if (currentCity) {
      setIsOpen(!isOpen);
    } else {
      alert("Please Pick location first");
    }
  };
  useMemo(() => {
    if (store.periods.length > 0) {
      dispatch({ type: "FILTER", source: date.day, result: store });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCity, store.periods]);
  useMemo(() => {
    filtering(date, store, dispatch);
    // eslint-disable-next-line
  }, [date, store.periods]);
  const handleClick = val => {
    dispatch({ type: "FILTER", source: val, result: store });
    setDate({ ...date, day: val });
  };
  const handleChangeFrom = value => {
    if (new Date(value).getFullYear() === 1970) {
      setDate({
        ...date,
        from: null
      });
      return;
    }
    setDate({
      ...date,
      from: new Date(value)
    });
  };
  const handleChangeTo = value => {
    if (new Date(value).getFullYear() === 1970) {
      setDate({
        ...date,
        to: null
      });
      return;
    }
    setDate({
      ...date,
      to: new Date(value)
    });
  };
  const handlePeriod = val => {
    if (val === "no Check-In/No Booking") {
      dispatch({
        type: "FILTER",
        source: "NO_CHECK_IN",
        from: val.toLowerCase(),
        date_start: date.from,
        date_end: date.to,
        result: store
      });
      setPeriod(val);
      return;
    }
    setPeriod(val);
    dispatch({
      type: "FILTER",
      source: "PERIOD",
      from: val.toLowerCase(),
      date_start: date.from,
      date_end: date.to,
      result: store
    });
  };
  return {
    handleChangeFrom,
    handleChangeTo,
    handleClick,
    setCurrentCity,
    date,
    currentCity,
    store,
    handlePeriod,
    period,
    isOpen,
    setIsOpen,
    clickHandler
  };
};

export default useCheckinTimesPanel;
