import React from "react";
import useWeekday from "../hooks/useWeekday";
import { WeeklyDropdown } from "./uiExporter";
import { days } from "../uitlity/constants";
import * as uuid from "uuid";
import { Day } from "../styled_components/styled_weekdays/index";
import blankCheckInHours from "../uitlity/blankCheckInHours";

const Weekday = ({ sortdays, toDisable, periodId, checked, anytime }) => {
  const { byDay, handleAddTime } = useWeekday({ sortdays, periodId });
  for (let i = 0; i < 7; i++) {
    if (byDay[i + 1].length > 0) {
      byDay[i + 1].push({
        ...blankCheckInHours,
        check_in_period_id: periodId.id,
        weekday: i + 1
      });
    }
  }

  return Object.keys(byDay).map((k, v) => {
    return (
      <>
        <Day key={uuid()}>{days[k]}</Day>
        {byDay[k].length > 0 ? (
          byDay[k].map((v, i) => {
            return (
              <WeeklyDropdown
                key={uuid()}
                hourly={v}
                reference={i > 0 ? byDay[k][i - 1] : null}
                toDisable={toDisable}
                anytime={anytime}
                handleAddTime={handleAddTime}
              />
            );
          })
        ) : (
          <WeeklyDropdown
            key={uuid()}
            hourly={{
              ...v,
              weekday: k,
              begin: "",
              end: "",
              check_in_period_id: periodId.id
            }}
            i={0}
            anytime={anytime}
            min={byDay[v]}
            toDisable={toDisable}
            handleAddTime={handleAddTime}
          />
        )}
      </>
    );
  });
};

export default Weekday;
