import styled from "styled-components";

export const Periods = styled.div`
  align-items: center;
  padding: 40px 15px 10px 15px;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding: 0 !important;
  min-height: 90vh;
  overflow-x: hidden;
  width: ${props =>
    props.childCount * 30 < 100 ? 100 : props.childCount * 30}vw;
  @media only screen and (max-width: 768px) {
    width: 100vw;
  }
`;

export const PeriodsHolder = styled.div`
  justify-content: ${props => (props.childCount > 3 ? "start" : "center")};
  align-items: start;
  flex-wrap: nowrap !important;
  margin: 0 -5px;
  min-height: 90vh;
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
  padding: 20px;
  width: 1200vw;
  transition: all 1s ease-in-out;
  @media only screen and (max-width: 1024px) {
    justify-content: flex-start;
  }
`;
export const Focused = styled.div`
  box-shadow: ${props => (props.shadow === true ? "0 0 20px #000" : "none")};
  align-self: start;
  padding: 10px 0;
`;
