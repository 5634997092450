import { takeLatest, put, call, fork } from "redux-saga/effects";
import fetching from "../api/dataFetching";
import addPeriodFetch from "../api/addPeriod";
import * as actions from "../constants/actionTypes";

// retrive list of locations for future apis and dropdown
function* cityList(payload) {
  try {
    yield put({ type: "LOADING", message: "LOADING" });
    const response = yield call(fetching, payload);
    yield put({ type: actions.CITIES_LIST, response });
    yield put({ type: actions.SUCCESS, message: "Success" });
  } catch (error) {
    yield put({ type: actions.ERROR, message: "An error occured" });
  }
}
// whatcher for disptaching api call
function* watchCityList() {
  yield takeLatest("CITIES", cityList);
}

// get the periods by location id
function* periodsList(payload) {
  try {
    yield put({ type: "LOADING", message: "LOADING" });
    const response = yield call(fetching, payload);
    yield put({ type: actions.PERIODS_LIST, response });
    yield put({ type: "PICKED_CITY" });
  } catch (error) {
    yield put({ type: actions.ERROR, message: "An error occured" });
  }
}
// whatcher for disptaching api call
function* watchPeriodList() {
  yield takeLatest("PERIODS", periodsList);
}

// action for adding new period to the store and dom
function* addPeriod({ period }) {
  yield put({ type: actions.ADD_NEW_PERIOD, period });
  yield put({ type: "NEW_PERIOD", data: period });
}
// whatcher for disptaching action
function* watchAddPeriod() {
  yield takeLatest("ADD_PERIOD", addPeriod);
}

// action and api call for removing period from dom and database
function* deletePeriod({ id, link, method }) {
  try {
    yield put({ type: "LOADING", message: "LOADING" });
    if (typeof id !== "string") {
      yield put({ type: "DELETE_CHANGES", id });
      yield call(fetching, { link, method });
    }
    yield put({ type: actions.DELETE_PERIOD, id });
    yield put({ type: "REMOVE_CHANGES", id });
    yield put({
      type: actions.SUCCESS,
      message: "Successfully deleted period"
    });
  } catch (error) {
    yield put({ type: actions.ERROR, message: "An error occured" });
  }
}

// whatcher for disptaching api call and store action
function* watchDeletePeriod() {
  yield takeLatest("DELETE", deletePeriod);
}

// action for filtering periods by date
function* filterPeriod(payload) {
  yield put({
    type: payload.source,
    result: payload.result,
    from: payload.from,
    to: payload.to,
    date_start: payload.date_start || new Date(0),
    date_end: payload.date_end || new Date(10000000000000)
  });
}
// whatcher for disptaching fileter action
function* watchFilterPeriod() {
  yield takeLatest("FILTER", filterPeriod);
}

// action for adding and updating hours field to the dome
function* addHours(payload) {
  yield put({ type: actions.ADD_HOURS, data: payload.data });
  if (typeof payload.data[0].id === "string") {
    yield put({ type: "ADD_HOURS_NEW_PERIOD", data: payload.data });
  } else {
    yield put({ type: "UPDATE_PERIOD", data: payload.data });
  }
}
// whatcher for disptaching action for adding or updating hours to the dom
function* watchAddHours() {
  yield takeLatest("HOURS", addHours);
}

//delete hours of one period
function* deleteHours(payload) {
  try {
    let i = 0;
    do {
      yield call(fetching, {
        link: `https://test-api.inbooma.net/api/check_in_hours/${payload.id[i]}`,
        method: "DELETE"
      });
    } while (payload.id[i++]);
    yield put({ type: actions.ADD_HOURS, data: payload.data });
  } catch (error) {
    yield put({ type: actions.ERROR, message: "An error occured" });
  }
}
//delete hours watcher
function* watchDeleteHours() {
  yield takeLatest("DELETE_TIME", deleteHours);
}
// action for adding new period to the database
function* submitNewPeriod({ payload }) {
  try {
    yield put({ type: "LOADING", message: "LOADING" });
    let i = 0;
    let res;
    while (i < payload.length) {
      res = yield call(addPeriodFetch, {
        link: "https://test-api.inbooma.net/api/check_in_periods",
        method: "POST",
        body: payload[i]
      });
      if (res.success) {
        yield put({ type: "SUBMIT_NEW_PERIOD", period: res.data });
        i++;
      } else {
        break;
      }
    }
    yield put({ type: "CLEAR_NEW_PERIODS", payload });
    yield put({
      type: actions.SUCCESS,
      message: "Successfully created new period"
    });
  } catch (error) {
    yield put({ type: actions.ERROR, message: "An error occured" });
  }
}
// whatcher for disptaching api call for adding period to the db
function* watchSubmitNewPeriod() {
  yield takeLatest("SUBMIT", submitNewPeriod);
}

function* patchPeriod({ payload }) {
  try { 
    yield put({ type: "LOADING", message: "LOADING" });
    const res = yield call(addPeriodFetch, {
      method: "PATCH",
      link: `https://test-api.inbooma.net/api/check_in_periods/${payload[0].id}`,
      body: {
        check_in_hours: payload[0].check_in_hours,
        check_in_outside_hours: payload[0].check_in_outside_hours,
        check_out_outside_hours: payload[0].check_out_outside_hours,
        valid_from: payload[0].valid_from,
        type: payload[0].type
      }
    });
    yield put({ type: actions.ADD_HOURS, data: [res.data] });
    yield put({ type: "CLEAR_PATCHES", payload });
    yield put({ type: actions.SUCCESS, message: "Successfully added hours" });
  } catch (error) {
    yield put({ type: actions.ERROR, message: "An error occured" });
  }
}
function* watchPatchPeriod() {
  yield takeLatest("PATCH_PERIOD", patchPeriod);
}

function* errorMessages() {
  yield put({ type: "ERROR", message: "Please pick a valid date" });
}

function* watchErrorMessages() {
  yield takeLatest("MESSAGE", errorMessages);
}
function* clearChanges(payload) {
  yield put({ type: "CLEAR_NEW_PERIODS", payload });
  yield put({ type: "CLEAR_PATCHES", payload });
}
function* watchClearChanges() {
  yield takeLatest("CLEAR", clearChanges);
}
//every new saga watcher should be added to sagas array
let sagas = [
  watchCityList,
  watchPeriodList,
  watchAddPeriod,
  watchFilterPeriod,
  watchDeletePeriod,
  watchAddHours,
  watchSubmitNewPeriod,
  watchPatchPeriod,
  watchErrorMessages,
  watchClearChanges,
  watchDeleteHours
];
export default function* startForman() {
  for (let saga of sagas) {
    yield fork(saga);
  }
}
