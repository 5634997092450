import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/scss/bootstrap.scss";
import { AddButton } from "../styled_components/exporter";
import useAddNewPeriod from "../hooks/useAddNewPeriod";
const AddNewPeriod = ({ currentCity,wideButton }) => {
  const { handleClick, periods } = useAddNewPeriod({ currentCity });
  return (
    <>
      <AddButton
        onClick={e => handleClick(e)}
        disable={
          periods.changes.newPeriods.length > 0 ||
          periods.changes.patchPeriods.length > 0
        }
        data-target="addNew"
        wideButton={wideButton}
      >
        <FontAwesomeIcon icon={faPlus} />
        Add new period
      </AddButton>
    </>
  );
};
export default AddNewPeriod;
