import { AddButton, Span } from "../exporter";
import styled from "styled-components";

export const Save = styled(Span)`
  font-size: 12px;
  color: #fff !important;
`;
export const SaveButton = styled(AddButton)`
  display: flex;
  position: relative;
  width: 45%;
  margin: 0 auto;
  padding: 0 20px !important;
  opacity: 1 !important;
  margin-top: 10px;
  height: 30px;
  align-items: center;
  justify-content: space-around;
`;
export const Error = styled.p`
  text-align: center;
  font-size: 0.85rem !important;
  font-weight: bold;
  color: #da3131;
`;
export const SaveContainer = styled.div`
  width: 100%;
  display: flex;
`;
