import React, { useState } from "react";
import { Boxes } from "../ui_components/uiExporter";
import "bootstrap/scss/bootstrap.scss";
import * as uuid from "uuid";
import {
  Periods,
  PeriodsHolder,
  Focused
} from "../styled_components/styled_checkin_periods/index";
import useCheckinPeriods from "../hooks/useCheckinPeriods";
import { CircularProgress } from "@material-ui/core";
const CheckInPeriods = () => {
  const [dropdown, setDropdown] = useState({});
  const {
    childCount,
    result,
    setCurrentSelected,
    active,
    selected,
    setSelected
  } = useCheckinPeriods();
  const ele = document.getElementById("scrollParent");
  // check in periods will be placed in boxes from filtered store that is using ALL filter by default
  return (
    <Periods id="boxes" childCount={childCount}>
      <PeriodsHolder
        id="scrollParent"
        childCount={childCount}
        style={{ opacity: result.messagesReducer === "LOADING" ? 0.3 : 1 }}
      >
        {result.filterPeriodsReducer.length > 0 ? (
          result.filterPeriodsReducer.map((period, i) => (
            <Focused shadow={i === active} key={uuid()}>
              <Boxes
                key={uuid()}
                childCount={childCount}
                active={i === active}
                selected={selected[i]}
                setSelected={setSelected}
                id={uuid()}
                data={period}
                dropdown={dropdown}
                setDropdown={setDropdown}
                parentElement={ele}
                i={i}
                first={active}
                setCurrentSelected={setCurrentSelected}
                style={{
                  opacity: result.messagesReducer === "LOADING" ? "1" : "0.4",
                  marginRight: "30px"
                }}
              />
            </Focused>
          ))
        ) : (
          <div style={{ textAlign: "center" }}>
            {result.messagesReducer !== "CITY" && (
              <p>No location picked, please pick one.</p>
            )}
            {result.messagesReducer === "CITY" &&
              result.periods.length === 0 && <p>No periods, add one.</p>}
          </div>
        )}
      </PeriodsHolder>
      {result.messagesReducer === "LOADING" && (
        <CircularProgress
          style={{
            position: "fixed",
            top: "30%",
            left: "50%",
            zIndex: 999999,
            opacity: "1 !important"
          }}
        />
      )}
    </Periods>
  );
};
export default CheckInPeriods;
