import "@babel/polyfill";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "regenerator-runtime/runtime";
import "core-js";
// polyfill only stable `core-js` features - ES and web standards:
import "core-js/stable";
import "core-js/features/date";
// polyfill only stable ES features:
import "core-js/es";
import "core-js/proposals/reflect-metadata";
// polyfill all stage 2+ proposals:
import "core-js/stage/2";
import "whatwg-fetch";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import store from "./store/configureStore";
import { LoginScreen } from "./components/exporter";
import App from "./App";
import Authenticate from "./uitlity/auth";
import "./index.css";

document.title = "Inbooma bike sharing app";

const MainApp = props => {
  return (
    <Provider store={store}>
      <App {...props} />
    </Provider>
  );
};
const LoginRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!Authenticate.isAuthenticated()) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};
const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (Authenticate.isAuthenticated()) {
          return <Component {...props} user={Authenticate.user} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};
ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <LoginRoute exact path="/login" component={LoginScreen} />
      <ProtectedRoute exact path="/" component={MainApp} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
if (window.Cypress) {
  window.store = store;
}
