import React from "react";
import DatePicker from "react-datepicker";
import { setHours, setMinutes } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import useSingleHours from "../hooks/useSingleHours";
import * as uuid from "uuid";
import { Dash } from "../styled_components/exporter";
import { minStart, minEnd, maxStart } from "../uitlity/minMaxTime";
const SingleHour = ({ hourly, handleHours, reference, anytime }) => {
  const {
    currentValStart,
    handleCurrentValStart,
    currentValEnd,
    handleCurrentValEnd
  } = useSingleHours({ hourly, handleHours });
  return (
    <>
      <DatePicker
        selected={!anytime && currentValStart}
        onChange={date => handleCurrentValStart(date)}
        onKeyDown={e => e.preventDefault()}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeFormat="HH:mm"
        minTime={minStart(reference)}
        maxTime={maxStart(currentValEnd)}
        dateFormat="HH:mm"
      />
      <div key={uuid()} className="react-datepicker-wrapper">
        <div className="react-datepicker__input-container">
          <Dash>&mdash;</Dash>
        </div>
      </div>
      <DatePicker
        selected={!anytime && currentValEnd}
        onChange={date => handleCurrentValEnd(date)}
        onKeyDown={e => e.preventDefault()}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeFormat="HH:mm"
        minTime={minEnd(reference, currentValStart)}
        maxTime={setHours(setMinutes(new Date(), 45), 23)}
        dateFormat="HH:mm"
      />
    </>
  );
};

export default SingleHour;
