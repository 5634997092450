import React from "react";
import useSaveNewPeriod from "../hooks/useSaveNewPeriod";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationTriangle,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import {
  Error,
  SaveButton,
  Save,
  SaveContainer
} from "../styled_components/styled_save_new/index";
const SaveNewPeriod = () => {
  const { clickHandler, message, cancelChanges } = useSaveNewPeriod();
  return (
    <>
      <Error>
        <span style={{ margin: "0 5px" }}>
          {message && message.length > 0 && (
            <FontAwesomeIcon icon={faExclamationTriangle} />
          )}
        </span>
        {message}
      </Error>
      <SaveContainer>
        <SaveButton round onClick={e => cancelChanges(e)}>
          <FontAwesomeIcon icon={faTimes} />
          <Save>Cancel</Save>
        </SaveButton>
        <SaveButton round onClick={e => clickHandler(e)}>
          <FontAwesomeIcon icon={faCheck} />
          <Save>Save</Save>
        </SaveButton>
      </SaveContainer>
    </>
  );
};

export default SaveNewPeriod;
