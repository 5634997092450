// authenticate class was made as singleton and it's based on local storage
class Authenticate {
  constructor() {
    this.authenticated = localStorage.getItem("inboomatoken");
    this.user = localStorage.getItem("inboomauser");
  }
  login(cb) {
    this.authenticated = localStorage.getItem("inboomatoken");
    this.user = localStorage.getItem("inboomauser");
    cb();
  }
  logout(cb) {
    localStorage.removeItem("inboomatoken");
    localStorage.removeItem("inboomauser");
    cb();
  }
  isAuthenticated() {
    return this.authenticated !== null && this.user !== null;
  }
  isAdmin() {
    let user = JSON.parse(this.user);
    return user && user.role === "admin";
  }
}
export default new Authenticate();
