import React from "react";
import { SingleHour } from "./uiExporter";
import useDailyComponent from "../hooks/useDailyComponent";
import { Hours, Hour } from "../styled_components/styled_daily/index";
import * as uuid from "uuid";
import "react-datepicker/dist/react-datepicker.css";

const DailyComponent = ({ daily, parentID, weekday, checked, anytime }) => {
  const { days, handleHours } = useDailyComponent({ daily, parentID, weekday });
  return (
    <Hours key={uuid()}>
      {days.map((day, i) => (
        <Hour>
          <SingleHour
            key={uuid()}
            anytime={anytime}
            hourly={day}
            reference={i > 0 ? days[i - 1] : null}
            index={i}
            handleHours={handleHours}
          />
        </Hour>
      ))}
    </Hours>
  );
};

export default DailyComponent;
