import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import combinedReducers from "./reducers/index";
import rootSaga from "./sagas/index";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(combinedReducers, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

export default store;
