import styled from "styled-components";
import { check } from "../../images/imageExporter";
export const CheckBoxesDiv = styled.div``;
export const Outside = styled.h3`
  font-size: 15px;
  margin: 5px 0 10px;
  color: #333;
  font-weight: 700;
`;

export const Label = styled.label`
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding-left: 30px;
  font-size: 15px;
`;
export const Input = styled.input`
  margin: 0;
  height: 0;
  width: 0;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  background-color: #fff;
  display: block;
  font-size: 15px;
  transition: all 0.2s;
  border: 1px solid #e3e3e3;
`;
export const LabelSpan = styled.span`
  background-color: #fff;
  border: 1px solid;
  border-color: ${props => (props.checked ? "#eb6a3a" : "#d3d3d3")};
  display: inline-block;
  height: 20px;
  width: 20px;
  position: absolute;
  left: 0;
  top: 1px;
  background-image: url(${props => (props.checked ? check : "")});
  background-size: 70%;
  background-position: 50%;
  background-repeat: no-repeat;
`;
