export const LOG_IN = "LOG_IN";
export const CITY_CHANGE = "CITY_CHANGE";
export const ADD_NEW_PERIOD = "ADD_NEW_PERIOD";
export const DELETE_PERIOD = "DELETE_PERIOD";
export const COPY_PERIOD = "COPY_PERIOD";
export const CITIES_LIST = "CITIES_LIST";
export const PERIODS_LIST = "PERIODS_LIST";
export const ADD_HOURS = "ADD_HOURS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const ERROR = "ERROR";
export const SUCCESS = "SUCCESS";
