const filtering = (date, store, cb) => {
  if (date.from && date.to) {
    cb({
      type: "FILTER",
      source: "DATES_RANGE_FILTER",
      from: date.from,
      to: date.to,
      result: store
    });
  }
  if (date.from && !date.to) {
    cb({
      type: "FILTER",
      source: "FILTER_FROM",
      from: date.from,
      to: date.to,
      result: store
    });
  }
  if (!date.from && date.to) {
    cb({
      type: "FILTER",
      source: "FILTER_TO",
      from: date.from,
      to: date.to,
      result: store
    });
  }
  if (!date.from && !date.to) {
    cb({
      type: "FILTER",
      source: date.day,
      from: date.from,
      to: date.to,
      result: store
    });
  }
};

export default filtering;
