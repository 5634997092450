export const times = ["ALL", "UPCOMING", "PAST"];

export const days = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday"
};

export const blankDays = {
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
  7: []
};

export const displayOptions = [
  "Daily",
  "Weekdays",
  "No Check-In / No Booking",
  "Check-In at anytime"
];
export const holidays = [{
    Neujahrstag: "2020-01-01"
  },
  {
    "Heilige Drei Könige": "2020-01-06"
  },
  {
    Frauentag: "2020-03-08"
  },
  {
    Gründonnerstag: "2020-04-09"
  },
  {
    Karfreitag: "2020-04-10"
  },
  {
    Ostersonntag: "2020-04-12"
  },
  {
    Ostermontag: "2020-04-13"
  },
  {
    "Tag der Arbeit": "2020-05-01"
  },

  {
    "Christi Himmelfahrt": "2020-05-21"
  },
  {
    Pfingstsonntag: "2020-05-31"
  },
  {
    Pfingstmontag: "2020-06-01"
  },
  {
    Fronleichnam: "2020-06-11"
  },
  {
    "Augsburger Friedensfest": "2020-08-08"
  },
  {
    "Mariä Himmelfahrt": "2020-08-15"
  },
  {
    Weltkindertag: "2020-09-20"
  }
  ,
  {
    "Tag der Deutschen Einheit": "2020-10-03"
  },
  {
    Reformationstag: "2020-10-31"
  },
  {
    Allerheiligen: "2020-11-01"
  },
  {
    "Buß- und Bettag": "2020-11-18"
  },
  {
    "1. Weihnachtstag": "2020-12-25"
  },
  {
    "2. Weihnachtstag": "2020-12-26"
  }
];
