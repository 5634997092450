import { useDispatch, useSelector } from "react-redux";
const useLocations = ({ currentCity, setCurrentCity }) => {
  const dispatch = useDispatch();
  const result = useSelector(data => data);
  const handleChange = val => {
    setCurrentCity(val.value);
    dispatch({
      type: "PERIODS",
      link: `https://test-api.inbooma.net/api/check_in_periods?location_id=${val.value}&sort=valid_from&direction=asc`,
      method: "GET"
    });
    dispatch({ type: "CLEAR_NEW_PERIODS" });
    dispatch({ type: "CLEAR_PATCHES" });
  };
  return {
    result,
    handleChange
  };
};
export default useLocations;
