const messagesReducer = (state = "", action) => {
  switch (action.type) {
    case "SUCCESS":
      return action.message;
    case "ERROR":
      return action.message;
    case "LOADING":
      return action.message;
    case "PICKED_CITY":
      return "CITY";
    default:
      return state;
  }
};

export default messagesReducer;
