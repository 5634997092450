const fetching = async ({ method, link }) => {
  let token = localStorage.getItem("inboomatoken");
  const options = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Pragma: "no-cache",
      Authorization: `Bearer ${token}`
    }
  };
  return fetch(link, options)
    .then(response => {
      return response.json();
    })
    .then(result => result);
};
export default fetching;
