import styled from "styled-components";

export const ListDiv = styled.div`
  width: 50%;
`;
export const Dot = styled.div`
  width: 5%;
  svg {
    font-size: 0.5em;
    display: ${props => (props.show ? "block" : "none")};
  }
`;

export const AddNew = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;
