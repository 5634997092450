import * as cities from "../constants/actionTypes";
const initalState = [];

const citiesReducer = (state = initalState, action) => {
  switch (action.type) {
    case cities.CITIES_LIST:
      return [...state, ...action.response.data];
    default:
      return [...state];
  }
};

export default citiesReducer;
