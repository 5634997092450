import { useEffect, useState } from "react";
import { setHours, setMinutes } from "date-fns";
const useSingleHours = ({ hourly, handleHours }) => {
  const [changed, setChanged] = useState({ start: false, end: false });
  const [currentValStart, setCurrentValStart] = useState(
    hourly.begin.length > 0
      ? setHours(
          setMinutes(new Date(), hourly.begin.split(":")[1]),
          hourly.begin.split(":")[0]
        )
      : ""
  );
  const [currentValEnd, setCurrentValEnd] = useState(
    hourly.end.length
      ? setHours(
          setMinutes(new Date(), hourly.end.split(":")[1]),
          hourly.end.split(":")[0]
        )
      : ""
  );
  const handleCurrentValStart = date => {
    setCurrentValStart(date);
    setChanged({ ...changed, start: true });
  };
  const handleCurrentValEnd = date => {
    setCurrentValEnd(date);
    setChanged({ ...changed, end: true });
  };

  useEffect(() => {
    // if both start and end hours are changed handlehours action will be invoked
    if (changed.start || changed.end) {
      handleHours(
        currentValStart,
        currentValEnd,
        hourly.check_in_period_id,
        hourly.id
      );
      setChanged({ ...changed, start: false, end: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changed.start, changed.end]);
  return {
    handleCurrentValEnd,
    handleCurrentValStart,
    currentValStart,
    currentValEnd
  };
};
export default useSingleHours;
