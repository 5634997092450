import moment from "moment";
import is from "is_js";

export const dateManipulation = date => {
  if (is.ie() || is.edge()) {
    return moment(date)._d;
  }
  return moment(date);
};

export const getYear = date => moment(date).format("YYYY");

export const dateCompare = date => moment(date).format("YYYY-MM-DD");

export const excludeDates = date => moment(date)._d;

export const germanDate = date => moment(date).format("DD.MM.YYYY.");
