import { useDispatch, useSelector } from "react-redux";
import blankCheckInHours from "../uitlity/blankCheckInHours";
import moment from "moment";
const useDailyComponent = ({ daily, parentID, weekday }) => {
  const dispatch = useDispatch();
  const { filterPeriodsReducer } = useSelector(data => data);
  let days = daily.length
    ? daily
    : [
        {
          begin: "",
          end: "",
          check_in_period_id: parentID,
          weekday
        }
      ];

  const handleHours = (start, end, parent, periodID) => {
    weekday = weekday === 0 ? 7 : weekday;
    // based upon parent that is check_in_period_id and periodID that is Id for actual period api call will be made
    // if there is periodID and parent patch call will be dispatch if not post call will be made
    if (typeof parentID === "string") {
      let parent = filterPeriodsReducer.filter(
        period => period.id === parentID
      )[0];
      if (parent.check_in_hours.length === 0) {
        let newPeriod = new Array(7);
        if (start === "" || end === "") return;
        for (let i = 0; i < newPeriod.length; i++) {
          newPeriod[i] = blankCheckInHours(
            moment(new Date(start)).format("HH:mm:ss"),
            moment(new Date(end)).format("HH:mm:ss"),
            i + 1,
            parentID
          );
        }
        parent.check_in_hours = newPeriod;
      } else {
        if (start === "" || end === "") return;
        parent.check_in_hours.push({
          ...blankCheckInHours(
            moment(new Date(start)).format("HH:mm:ss"),
            moment(new Date(end)).format("HH:mm:ss"),
            weekday,
            parentID
          )
        });
      }
      dispatch({ type: "HOURS", data: [parent] });
      return;
    } else {
      let parent = filterPeriodsReducer.filter(
        period => period.id === parentID
      )[0];
      if (typeof periodID === "number") {
        let index = parent.check_in_hours.findIndex(
          hour => hour.id === periodID
        );
        parent.check_in_hours[index] = {
          ...parent.check_in_hours[index],
          begin: moment(new Date(start)).format("HH:mm:ss"),
          end: moment(new Date(end)).format("HH:mm:ss"),
          weekday
        };
      } else {
        if (!start || !end) return;
        parent.check_in_hours.push({
          ...blankCheckInHours(
            moment(new Date(start)).format("HH:mm:ss"),
            moment(new Date(end)).format("HH:mm:ss"),
            weekday,
            parentID
          )
        });
      }
      dispatch({ type: "HOURS", data: [parent] });
    }
  };
  return {
    days,
    handleHours
  };
};
export default useDailyComponent;
