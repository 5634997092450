import React from "react";
import {
  Outside,
  Label,
  Input,
  LabelSpan
} from "../styled_components/styled_checkboxes/index";
import moment from "moment";
const CheckBoxes = ({ checked, setChecked, date, active }) => {
  return (
    <div>
      <Outside>Outside hours</Outside>
      <Label>
        Check-In on request{" "}
        <Input
          type="checkbox"
          checked={checked.checkIn}
          disabled={
            moment(new Date(date)).format("YYYY-MM-DD") <
              moment(new Date()).format("YYYY-MM-DD") && !active
          }
        />
        <LabelSpan
          onClick={() => setChecked({ ...checked, checkIn: !checked.checkIn })}
          id="check_in"
          checked={checked.checkIn}
        />
      </Label>
      <Label>
        Check-Out on request{" "}
        <Input
          type="checkbox"
          checked={checked.checkOut}
          disabled={
            moment(new Date(date)).format("YYYY-MM-DD") <
              moment(new Date()).format("YYYY-MM-DD") && !active
          }
        />
        <LabelSpan
          id="check_out"
          onClick={() =>
            setChecked({ ...checked, checkOut: !checked.checkOut })
          }
          checked={checked.checkOut}
        />
      </Label>
    </div>
  );
};
export default CheckBoxes;
