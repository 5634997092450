import styled from "styled-components";

export const AddButton = styled.button`
  padding: 10px 30px;
  display: inline-block;
  border-radius: ${props =>
    props.round ? "999px !important" : "10px !important"};
  background-color: #eb6a3a !important ;
  color: #fff;
  border: none;
  text-align: ${props => props.wideButton ? "left" : "center"}
  position: absolute;
  width: ${props => props.wideButton ? "100%" : "auto"}
  opacity: ${props => (props.disable ? "0.4" : "1")};
  @media only screen and (max-width: 576px) {
    width: 90%;
  }
`;
export const SaveBtn = styled.button`
  padding: 10px 30px;
  display: inline-block;
  border-radius: 999px;
  background-color: #eb6a3a !important;
  color: #fff;
  border: none;
  position: absolute;
  opacity: 1;
  z-index: 99999 !important;
  top: 16px;
  width: 76%;
`;
export const DeleteHours = styled.p`
  display: inline-block;
  border-radius: 999px;
  color: ${props => (props.toShow ? "#eb6a3a" : "#f9f9fc")};
  border: none;
  opacity: 1;
  z-index: 99999 !important;
  font-size: 14px !important;
  margin-left: 10px;
  cursor: pointer;
  pointer-events: ${props => (props.toShow ? "" : "none")};
`;
