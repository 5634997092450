import { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { dateCompare } from "../uitlity/momentPoly";
const useCheckinPeriod = () => {
  const [childCount, setChildCount] = useState(0);
  const [currentSelected, setCurrentSelected] = useState();
  const [active, setActive] = useState();
  const [selected, setSelected] = useState({});
  const result = useSelector(data => data, shallowEqual);
  useEffect(() => {
    setChildCount(result.filterPeriodsReducer.length);
    if (result.changes.newPeriods.length > 0) {
      setCurrentSelected(result.periods.slice(-1)[0].id);
      setActive(result.periods.length - 1);
      return;
    }
    if (
      result.periods.filter(period => period.id === currentSelected).length ===
      0
    ) {
      setCurrentSelected();
    }

    if (!currentSelected) {
      let findId = result.filterPeriodsReducer
        .filter(period => dateCompare(period.valid_from) <= dateCompare())
        .slice(-1)[0];
      let index;
      if (findId) {
        index = result.periods.findIndex(period => period.id === findId.id);
      }
      setActive(index);
    } else {
      let index = result.filterPeriodsReducer.findIndex(
        period => period.id === currentSelected
      );
      setActive(index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    result.periods,
    result.messagesReducer,
    currentSelected,
    result.changes.newPeriods,
    result.changes.patchPeriods,
    result.filterPeriodsReducer
  ]);

  return {
    childCount,
    result,
    currentSelected,
    setCurrentSelected,
    active,
    selected,
    setSelected
  };
};
export default useCheckinPeriod;
