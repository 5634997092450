import styled from "styled-components";

export const NavHeader = styled.header`
  padding: 0 15px;
  background-color: #fff;
  height: 80px;
`;

export const Navbar = styled.nav`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  @media only screen and (min-width: 1200px) {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
`;
export const Brand = styled.span`
  display: inline-block;
  padding: 0;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
`;
export const LogoBrand = styled.img`
  height: 55px;
  vertical-align: middle;
  border-style: none;
`;

export const MainNav = styled.div`
  display: flex !important;
  flex-basis: auto;
  display: fixed;
`;

// .collapse:not(.show) {

//     display: none;

// }
// .navbar-collapse {

//     flex-basis: 100%;
//     flex-grow: 1;
//     align-items: center;
