import React from "react";
import DatePicker from "react-datepicker";
import { setHours, setMinutes } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { Dash } from "../styled_components/exporter";
import { minStart, maxStart, minEnd } from "../uitlity/minMaxTime";
import useWeeklyDropdown from "../hooks/useWeeklyDropdown";
import { HoursWeek } from "../styled_components/styled_weekdays/index";
import { DeleteHours } from "../styled_components/exporter";
import * as uuid from "uuid";
const WeeklyDropdown = ({ handleAddTime, hourly, min, reference, anytime }) => {
  const {
    startDate,
    handleStartDate,
    endDate,
    handleEndDate,
    handleDeleteHours
  } = useWeeklyDropdown({ hourly, handleAddTime, reference });
  const testArray = hour =>
    hour.end &&
    hour.end
      .split(":")
      .slice(0, 2)
      .join(":") === "23:59" &&
    hour.begin &&
    hour.begin
      .split(":")
      .slice(0, 2)
      .join(":") === "00:00";
  let showIt = testArray(hourly);

  return (
    <HoursWeek key={uuid()}>
      <DatePicker
        selected={!showIt && startDate ? startDate : ""}
        onChange={date => handleStartDate(date)}
        onKeyDown={e => e.preventDefault()}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeFormat="HH:mm"
        minTime={minStart(reference)}
        maxTime={maxStart(endDate)}
        dateFormat="HH:mm"
      />
      <div key={uuid()} className="react-datepicker-wrapper">
        <div className="react-datepicker__input-container">
          <Dash>&mdash;</Dash>
        </div>
      </div>
      <DatePicker
        selected={!showIt && endDate ? endDate : ""}
        onChange={date => handleEndDate(date)}
        onKeyDown={e => e.preventDefault()}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeFormat="HH:mm"
        minTime={minEnd(reference, startDate)}
        maxTime={setHours(setMinutes(new Date(), 45), 23)}
        dateFormat="HH:mm"
      />
      <DeleteHours
        toShow={startDate && !anytime}
        onClick={e =>
          window.confirm("Do you really want to delete") &&
          handleDeleteHours(hourly.id, hourly.check_in_period_id)
        }
      >
        x
      </DeleteHours>
    </HoursWeek>
  );
};

export default WeeklyDropdown;
